<!--div class="rectangle" mwlResizable-->

<div id="unity-container" class="unity-desktop">
    <div id="unity-loading-bar">
        Loading the game viewer...
    </div>
    <canvas id="unity-canvas"></canvas>
    <div id="unity-warning"> </div>
    <div id="unity-footer">
        <div id="unity-webgl-logo"></div>
        <div id="unity-fullscreen-button"></div>
    </div>
</div>

<!--div class="resize-handle-bottom" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div-->