
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject, Subscriber } from 'rxjs';
import { environment } from 'environments/environment';
import { Inject, Injectable } from '@angular/core';


//TODO: Change all "any" types to concrete, importable data types.
//TODO: clean up this class to simplify usage / logging of responses/errors to consoles is not done correctly.

type APIRequest = LobbyRequest | UserRequest | GameRequest;

type UserRequest = {
    type: "User",
    params?: {},
    path: "Delete" | "Get" | "GetLobby",
    body?: {}
}

type LobbyRequest = {
    type: "Lobby",
    params?: {},
    path: "Get" | "Create" | "CreateAndJoin" | "Configure" | "Start" | "Games" | "Delete" | "Join",
    body?: {}
}

type GameRequest = {
    type: "Game",
    params?: {},
    path: "CurrentContent" | "FormSubmit" | "AutoFormSubmit",
    body?: {}
}

@Injectable({
    providedIn: 'root'
})
export class API {

    private userId: string;
    private userOrdinalSet: boolean;
    private hostOrdinalSet: boolean;
    private hostOrdinal: string; // This tracks lobby they have made
    private userOrdinal: string; // This tracks lobby they have joined (technically they can differ -_-)

    // TODO: instantiate api via dependency injection / make it injectable.
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject('userId')userId: string,
        @Inject('userLobbyId')userLobbyId: string,
        @Inject('hostLobbyId')hostLobbyId: string,
        @Inject('BASE_API_URL') private baseUrl: string)
    {
        this.userId = userId;
        this.updateUserLobbyId(userLobbyId);
        this.updateHostLobbyId(hostLobbyId);
    }
 


    logRequest = (r: APIRequest) => {
        return (data: any) => {
            console.log(`[APIResponse] ${r.type}/${r.path}`, data)
            }
    }

    logError = (r: APIRequest) => {
        return (data: any) => {
            console.log(`[APIResponse] ${r.type}/${r.path}`, data)
            }
    }

    request = (r: APIRequest): Observable<Object> => {
        console.log(`[APIRequest] ${r.type}/${r.path}`, r.body)
        let obs: Observable<Object> = null;
        if (!this.hostOrdinalSet && !this.userOrdinalSet)
        {
            // Restricted set of methods if no ordinal set.
            switch (r.type) {
                case "Lobby":
                    switch (r.path) {
                        case "Create":
                        case "Games":
                        case "CreateAndJoin": 
                            break;
                        default: 
                            console.error(`[APIRequest] ${r.type}/${r.path}  - BAD REQUEST - NO LOBBY SET`)
                            return;
                    }
                    break;
                default: 
                    console.error(`[APIRequest] ${r.type}/${r.path}  - BAD REQUEST - NO LOBBY SET`)
                    return;
            }
        } else if (this.hostOrdinalSet && !this.userOrdinalSet)
            {
                // Restricted set of methods if no user ordinal set.
                switch (r.type) {
                    case "Lobby":
                        switch (r.path) {
                            case "Join":  // You can run lobby commands except join
                                console.error(`[APIRequest] ${r.type}/${r.path}  - BAD REQUEST - NO USER LOBBY SET`)
                                return;
                            default:
                                break;
                        }
                        break;                
                    default: 
                        console.error(`[APIRequest] ${r.type}/${r.path}  - BAD REQUEST - NO USER LOBBY SET`)
                        return; // Bypass everything else
                }
        }else if (!this.hostOrdinalSet && this.userOrdinalSet)
        {
            // Restricted set of methods if no host ordinal set.
            switch (r.type) {
                case "Lobby":
                    switch (r.path) {
                        case "Create":
                        case "Games":
                        case "CreateAndJoin": 
                        case "Join":  // You can run lobby join, setup, and non-lobby commands
                            break;
                        default:
                            console.error(`[APIRequest] ${r.type}/${r.path}  - BAD REQUEST - NO HOST LOBBY SET`)
                            return; // Bypass all other lobby commands
                    }
                    break;                
                default: 
                    break; // Allow everything else
            }
        }

        switch (r.type) {
            case "Lobby":
                switch (r.path) {
                    case "Get":
                    case "Delete":
                    case "Create":
                    case "Games": obs = this.Get(r);
                        break;
                    case "CreateAndJoin":
                    case "Configure":
                    case "Start":
                    case "Join": obs = this.Post(r);
                        break;
                    default: return;
                }
                break;
            case "User":
                switch (r.path) {
                    case "Get":
                    case "GetLobby":
                    case "Delete": obs = this.Get(r);
                        break;
                    default: return;
                }
                break;
            case "Game":
                switch (r.path) {
                    case "CurrentContent": obs = this.Get(r);
                        break;
                    case "FormSubmit":
                    case "AutoFormSubmit": obs = this.Post(r);
                        break;
                    default: return;
                }
                break;
            default: return;
        }
        return obs;
    } 

    Get(r: APIRequest): Observable<Object> {
        var url = this.getAPIPath(r);
        var options = {params:r.params ? r.params : {}}
        options.params["id"]=this.userId;

        // I do not like this sam I am.
        var observable = new Observable(subscriber =>
        {
            this.http.get(url, options).subscribe(
            {
                next: (x) => {
                    subscriber.next(x);
                },
                error: (err: unknown) => {
                        subscriber.error(err);
                },
                complete: () => {
                    subscriber.complete();
                }
            });
        });
        return observable;
    }

    Post(r: APIRequest): Observable<Object> {
        var url = this.getAPIPath(r);
        var options = {
            params: r.params ? r.params : {},
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        options.params["id"]=this.userId;

        var observable = new Observable(subscriber => {
            this.http.post(url, r.body, options).subscribe(
                {
                    next: (x) => {
                        subscriber.next(x);
                    },
                    error: (err: unknown) => {
                        subscriber.error(err);
                    },
                    complete: () => {
                        subscriber.complete();
                    }
                });
        });
        return observable;
    }    

    getAPIPath = (r: APIRequest): string => {
        var tempOrdinal = this.userOrdinal;       
        switch (r.type) {
            case "Lobby":
                switch (r.path) {                    
                    case "Create":
                    case "CreateAndJoin":
                        tempOrdinal = ""; // Suppress the ordinal for create and createAndJoin requests
                        break;
                    case "Join": break; // leave the ordinal as user for join
                    default: tempOrdinal = this.hostOrdinal; // use host ordinal if its a lobby request that isn't join, create, or createandjoin
                        break;
                }
                break;
            default: break;
        }

        return `${this.baseUrl}${tempOrdinal}api/v1/${r.type}/${r.path}`;
    }

    isAUserOrdinalKnown = () : boolean => {
        return this.userOrdinalSet;        
    }
    isAHostOrdinalKnown = () : boolean => {
        return this.hostOrdinalSet;        
    }

    updateHostLobbyId = (lobbyId: string) => {
        const idKeyName: string = 'scrawlBrawlHostLobbyId';
        console.log("New Host LobbyId: "+lobbyId);
        if (lobbyId != null && lobbyId.length>=4)
        {
            // Check if lobbyId has at least 5 characters
            this.hostOrdinal = lobbyId.length >= 5 ? lobbyId.substring(4)+"/" : "";

            // Even if the ordinal was empty, this still counts as setting the ordinal because thats what the shortened lobby id implied (this happens in local environments)
            this.hostOrdinalSet = true;
            
            // Store this lobby id in cookies
            localStorage.setItem(idKeyName, lobbyId);
        }
        else
        {
            this.hostOrdinalSet = false; // Block any API requests that require knowing the ordinal
            this.hostOrdinal = ""; // Route all requests to the load balanced URL. 

            localStorage.removeItem(idKeyName);
        }
        
    }
    updateUserLobbyId = (lobbyId: string) => {
        const idKeyName: string = 'scrawlBrawlUserLobbyId';
        console.log("New User LobbyId: "+lobbyId);
        if (lobbyId != null && lobbyId.length>=4)
        {
            // Check if lobbyId has at least 5 characters
            this.userOrdinal = lobbyId.length >= 5 ? lobbyId.substring(4)+"/" : "";

            // Even if the ordinal was empty, this still counts as setting the ordinal because thats what the shortened lobby id implied (this happens in local environments)
            this.userOrdinalSet = true;
            
            // Store this lobby id in cookies
            localStorage.setItem(idKeyName, lobbyId);
        }
        else{
            this.userOrdinalSet = false; // Block any API requests that require knowing the ordinal
            this.userOrdinal = ""; // Route all requests to the load balanced URL. 

            localStorage.removeItem(idKeyName);
        }
    }
}
