    <a [routerLink]="link" 
    class="scrawlbutton-link" 
    [class.keyboard-focus]="isKeyboardFocus"
    [class.is-focused]="isFocused"
    (click)="onClickButton($event);"
    (mouseenter)="onMouseHover()"  
    (focus)="onHostFocus($event)"
    (blur)="onHostBlur()"
    (keydown)="onKeyDown($event)"
    tabindex="0">
     <div class="scrawlbutton">
         <div class="scrawlbutton-label" [ngStyle]="{'color': textColor ? textColor : 'white'}">
             <ng-content></ng-content>
         </div>
         <div class="{{color}}-scrawlbutton-base"></div>
         <div class="scrawlbutton-outline"></div>
     </div>
 </a>