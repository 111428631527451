import { Component, OnInit, ElementRef, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { UnityViewer } from '@core/http/viewerInjectable';
import { Inject } from '@angular/core';
import { ResizableModule, ResizeEvent } from 'angular-resizable-element';

//Used to create a single instance of the unity viewer

@Component({
  selector: 'unity',
  templateUrl: './unity.component.html',
  styleUrls: ['./unity.component.css']
})
export class UnityComponent implements OnInit {
  @Input() useLegacyViewer: boolean = false;
  @Output() onConnectionClosed = new EventEmitter<string>();

  gameInstance: any;
  
  unityViewer: UnityViewer;
  progress = 0;
  isReady = true;
  element;

  constructor(@Inject(UnityViewer) private theViewer: UnityViewer, element: ElementRef,private ngZone: NgZone) {
    this.unityViewer = theViewer;
    this.element = element.nativeElement;  
  }

  ngOnInit(): void {
    const script = document.createElement('script');
        
    if (this.useLegacyViewer) {
      script.src = '/legacyviewer/Build/WebGlAsm.asm.loader.js';
    } else {
      script.src = '/viewer/Build/WebGl.loader.js';
    }

    script.onload = () => {
      // Run initialization outside of Angular's zone
      this.ngZone.runOutsideAngular(() => {
        this.unityViewer.InitializeViewer('gameContainer', this.useLegacyViewer, {
          onSocketConnectionClosed: (data: string) => {
            // Run callback inside Angular's zone to ensure UI updates
            this.ngZone.run(() => {
              this.onConnectionClosed.emit(data);
            });
          }
        });
      });
    };

    script.onerror = (error) => {
      console.error('Error loading script:', error);
    };

    document.body.appendChild(script);    
  }
}