// lobby-input.component.ts
import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lobby-input',
  template: `
    <div class="dialog-container" tabindex="0">
      <h2 mat-dialog-title>Enter Lobby ID</h2>
      <mat-dialog-content>
        <div class="position-container">
          <div *ngFor="let char of positions; let i = index" 
               [class.selected]="i === selectedPosition && focusedElement === 'positions'"
               class="position-box">
            {{ char || ' ' }}
          </div>
        </div>

        <div class="instructions">
          Use LEFT/RIGHT to move between positions<br>
          Use UP/DOWN to change characters
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <button mat-button 
                [class.selected]="focusedElement === 'join'"
                [disabled]="!isValidInput()"
                (click)="handleJoin()">
          Join
        </button>
        <button mat-button 
                [class.selected]="focusedElement === 'close'"
                (click)="dialogRef.close()">
          Close
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [`
    .dialog-container {
      outline: none;
    }
    .position-container {
      display: flex;
      gap: 8px;
      justify-content: center;
      margin-bottom: 2rem;
    }
    .position-box {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: monospace;
      background-color: #f3f4f6;
      border-radius: 8px;
    }
    .selected {
      background-color: #3b82f6;
      color: white;
    }
    .instructions {
      text-align: center;
      color: #666;
      margin-bottom: 2rem;
    }
  `]
})
export class LobbyInputComponent {
  positions: string[] = Array(6).fill('');
  selectedPosition = 0;
  focusedElement: 'positions' | 'join' | 'close' = 'positions';
  
  private hexChars = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];

  constructor(public dialogRef: MatDialogRef<LobbyInputComponent>) {
    this.dialogRef.disableClose = true;
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    switch (event.key) {
      case 'ArrowUp': {
        if (this.focusedElement === 'positions') {
          // Only handle character change, don't move position
          const currentChar = this.positions[this.selectedPosition];
          const currentIndex = this.hexChars.indexOf(currentChar);
          this.positions = [
            ...this.positions.slice(0, this.selectedPosition),
            this.hexChars[(currentIndex + 1) % this.hexChars.length],
            ...this.positions.slice(this.selectedPosition + 1)
          ];
          // Don't auto-advance position
          return;
        } else {
          this.focusedElement = 'positions';
        }
        break;
      }

      case 'ArrowDown': {
        if (this.focusedElement === 'positions') {
          // Only handle character change, don't move position
          const currentChar = this.positions[this.selectedPosition];
          const currentIndex = this.hexChars.indexOf(currentChar);
          this.positions = [
            ...this.positions.slice(0, this.selectedPosition),
            this.hexChars[(currentIndex - 1 + this.hexChars.length) % this.hexChars.length],
            ...this.positions.slice(this.selectedPosition + 1)
          ];
          // Don't auto-advance position
          return;
        } else if (this.focusedElement === 'join') {
          this.focusedElement = 'close';
        } else {
          this.focusedElement = 'positions';
        }
        break;
      }

      case 'ArrowLeft': {
        if (this.focusedElement === 'positions') {
          this.selectedPosition = Math.max(0, this.selectedPosition - 1);
        } else if (this.focusedElement === 'close') {
          this.focusedElement = 'join';
        }
        break;
      }

      case 'ArrowRight':{
        if (this.focusedElement === 'positions') {
          if (this.selectedPosition < 5) {
            this.selectedPosition++;
          } else {
            this.focusedElement = 'join';
          }
        } else if (this.focusedElement === 'join') {
          this.focusedElement = 'close';
        } else if (this.focusedElement === 'close') {
          this.focusedElement = 'positions';
        }

        break;
      }
      case 'Enter': {
          if (this.focusedElement === 'join' && this.isValidInput()) {
            this.handleJoin();
          } else if (this.focusedElement === 'close') {
            this.dialogRef.close();
          }
        break;
      }
    }
  }

  isValidInput(): boolean {
    const filledPositions = this.positions.filter(char => char !== '').length;
    return filledPositions >= 4;
  }

  handleJoin() {
    const lobbyId = this.positions.filter(char => char !== '').join('');
    if (this.isValidInput()) {
      this.dialogRef.close(lobbyId);
    }
  }
}