import { Component } from '@angular/core';
import { FooterService } from './footer.service';
import {footerLinksOrder, footerLinks, socialLinks} from './footer'
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  sections: any;
  sectionsOrder: any;
  socialLinks: any;

  isTVDisplay: boolean = false;
  
  constructor(private router: Router, public activatedRoute: ActivatedRoute, public location: Location){
    this.checkRoute(location.prepareExternalUrl(location.path()));
    this.sections = footerLinks;
    this.sectionsOrder = footerLinksOrder;
    this.socialLinks = socialLinks;
    router.events.subscribe((val) => { 
      this.checkRoute(this.router.url)
    });
  }

  checkRoute = (url: string) : void => {
    this.isTVDisplay = (url.toLowerCase().startsWith('/roku'));
  }



}
