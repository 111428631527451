export class SplashScreen {
    private static splashDiv: HTMLDivElement | null = null;
  
    static show() {
      if (this.splashDiv) return;
  
      this.splashDiv = document.createElement('div');
      this.splashDiv.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      `;
  
      const messageDiv = document.createElement('div');
      messageDiv.style.cssText = `
        background-color: white;
        padding: 20px 40px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
  
      const message = document.createElement('p');
      message.style.cssText = `
        font-size: 24px;
        font-weight: 600;
        color: #333;
        margin: 0 0 20px 0;
        text-align: center;
        line-height: 1.4;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        padding: 0 20px;
      `;
      message.innerHTML = 'Preparing the viewer<br>Please Wait...';

      // Create and add the image
      const img = document.createElement('img');
      img.src = '/assets/svgs/working.svg';
      img.style.cssText = `
        width: 300px;  /* Adjust size as needed */
        height: auto;
        margin-top: 10px;
        margin-bottom: 20px;
      `;
  
      messageDiv.appendChild(message);
      messageDiv.appendChild(img);
      this.splashDiv.appendChild(messageDiv);
      document.body.appendChild(this.splashDiv);
    }
  
    static hide() {
      if (this.splashDiv) {
        this.splashDiv.remove();
        this.splashDiv = null;
      }
    }
}