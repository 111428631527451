import { Component, ElementRef, Input, Output, EventEmitter, HostListener, ViewEncapsulation, Optional, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'scrawlbutton',
  templateUrl: './scrawlbutton.component.html',
  styleUrls: ['./scrawlbutton.component.css'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.is-focused]': 'isFocused && isKeyboardFocus',
    '[attr.tabindex]': '0',
    '[style.outline]': 'none',
    '[class.in-dialog]': 'isInDialog',
    'style': 'outline: none !important; -webkit-tap-highlight-color: transparent;'
  },
})
export class ScrawlButtonComponent {
  @Input() color: string = 'blue';
  @Input() textColor: string;
  @Input() link: string;
  @Output() onClick = new EventEmitter<MouseEvent>();
  
  isFocused = false;
  isKeyboardFocus = false;
  isInDialog = false;
  private hadKeyboardEvent = false;
  private initialFocusSet = false;  // Track if we've handled first focus

  // Store the handler functions
  private keydownHandler = () => {
    this.hadKeyboardEvent = true;
    //console.log('Keyboard event detected');  // Debug log
  };

  private mousedownHandler = () => {
    this.hadKeyboardEvent = false;
    //console.log('Mouse event detected');  // Debug log
  };

  private arrowKeyHandler = (event: KeyboardEvent) => {
    if (event.key.startsWith('Arrow')) {
      this.hadKeyboardEvent = true;
      if (document.activeElement === this.element.nativeElement ||
          document.activeElement === this.element.nativeElement.querySelector('.scrawlbutton-link')) {
        this.handleKeyboardFocus();
      }
    }
  };

  // Helper method to set focus state
  private handleKeyboardFocus() {
    this.hadKeyboardEvent = true;
    this.isKeyboardFocus = true;
    this.isFocused = true;
    this.initialFocusSet = true;
  }

  constructor(
    private element: ElementRef,
    @Optional() private dialog: MatDialog
  ) {
    this.isInDialog = !!this.dialog;
    
    // Add event listeners with the stored handlers
    document.addEventListener('keydown', this.keydownHandler);
    document.addEventListener('mousedown', this.mousedownHandler);
    document.addEventListener('keydown', this.arrowKeyHandler);
  }
  ngAfterViewInit() {
    // Check if this element is focused after view initialization
    setTimeout(() => {
      if (document.activeElement === this.element.nativeElement ||
          document.activeElement === this.element.nativeElement.querySelector('.scrawlbutton-link')) {
        this.handleKeyboardFocus();
      }
    });
  }

  @HostListener('focus', ['$event'])
  onHostFocus(event: FocusEvent) {
    if (!this.initialFocusSet && event.relatedTarget) {
      // If this is first focus and we came from another element
      this.handleKeyboardFocus();
    } else {
      this.isFocused = true;
      setTimeout(() => {
        this.isKeyboardFocus = this.hadKeyboardEvent;
      }, 0);
    }
  }

  @HostListener('mouseenter')
  onMouseHover() {
    this.hadKeyboardEvent = false;
    this.isKeyboardFocus = false;
    this.isFocused = false;
    //console.log('Mouse hover detected, turning off keyboard focus');
}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.hadKeyboardEvent = true;

    if (event.key.startsWith('Arrow')) {
      this.handleKeyboardFocus();
    }
    
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.onClick.emit(new MouseEvent('click'));
      if (this.link) {
        window.location.href = this.link;
      }
      this.isFocused = false;
    }
  }

  @HostListener('blur')
  onHostBlur() {
    this.isFocused = false;
    this.isKeyboardFocus = false;
    //console.log('Button blurred');
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.isKeyboardFocus = false;
    this.hadKeyboardEvent = false;
    //console.log('Mouse down on button');
  }

  public focusButton() {
    const linkElement = this.element.nativeElement.querySelector('.scrawlbutton-link');
    if (linkElement) {
      linkElement.focus();
      this.isKeyboardFocus = this.hadKeyboardEvent;
    }
  }


  onClickButton(event: MouseEvent) {
    this.onClick.emit(event);
    this.isFocused = false;
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.keydownHandler);
    document.removeEventListener('mousedown', this.mousedownHandler);
    document.removeEventListener('keydown', this.arrowKeyHandler);
  }
}